<template>
  <lf-card class="max-w-screen-md py-6 mb-8">
    <template v-slot>
      <loader :isLoading="isSubmitting || isLoading" />
      <form @submit.prevent="onSubmit">
        <div class="pt-2 space-y-5">
          <lf-switch
            name="data.send_docusign_email"
            v-model="client.send_docusign_email"
            :disabled="isFundingAdvisor || isClientFundingAdvisor"
          >
            <lf-h3>
              {{ $t("ORGANIZATION.API_CONFIG.SEND_EMAIL_DOCUSIGN") }}
            </lf-h3>
            <span>
              {{ $t("ORGANIZATION.API_CONFIG.DOCUSIGN_EMAIL_DESCRIPTION") }}
            </span>
          </lf-switch>
          <lf-switch
            name="data.send_plaid_email"
            v-model="client.send_plaid_email"
            :disabled="isFundingAdvisor || isClientFundingAdvisor"
          >
            <lf-h3>{{ $t("ORGANIZATION.API_CONFIG.SEND_EMAIL_PLAID") }}</lf-h3>
            <span>
              {{ $t("ORGANIZATION.API_CONFIG.BANK_CONN_EMAIL_DESCRIPTION") }}
            </span>
          </lf-switch>
        </div>
        <div class="mt-5 space-y-5">
          <access-control :roles="NOT_FA_ROLE_GROUP">
            <div
              class="flex justify-end items-center min-w-full border-t pt-6 pr-5"
            >
              <primary-button type="submit" :disabled="isSubmitting">
                {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
              </primary-button>
            </div>
          </access-control>
        </div>
      </form>
    </template>
  </lf-card>
</template>

<script setup>
import { computed, reactive, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";

import { useAuth } from "@/hooks/auth";
import { NOT_FA_ROLE_GROUP } from "@/helpers/constants";

const { getters, dispatch } = useStore();
const { isFundingAdvisor, isClientFundingAdvisor } = useAuth();

const isLoading = ref(false);
const client = computed(() => getters["clients/active"]);

const initialValues = reactive({
  data: {
    send_docusign_email: client.value.send_docusign_email,
    send_plaid_email: client.value.send_plaid_email
  }
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});

const onSubmit = handleSubmit(async (values, actions) => {
  const { data } = values;
  try {
    await dispatchAction(
      { data, id: client.value.id },
      actions,
      "clients/updateClient"
    );
  } catch {
    //
  }
});

onMounted(async () => {
  isLoading.value = true;
  await dispatch("auth/getAuthClientIntegration", client.value.id);
  isLoading.value = false;
});
</script>
